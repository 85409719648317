.banner .nav li {
  @extend .nav-item;
}

.banner .nav a {
  @extend .nav-link;
}

.navbar-text {
  margin-left: 1rem;
}

.navbar-brand {
  font-weight: $headings-font-weight;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:active {
  color: $body-color;
}

.navbar-light .navbar-nav .active .nav-link {
  color: $primary;
}

@include media-breakpoint-up(md) {
  .navbar-brand {
    font-size: $font-size-xl;
  }

  .navbar-text {
    margin-left: 1.25rem;
  }
}

.navbar-nav {
  flex-direction: row;
}

.nav-item {
  margin-left: 1.25rem;
}

@include media-breakpoint-up(md) {
  .nav-item {
    margin-left: 0.5rem;
  }
}

@include media-breakpoint-down(sm) {
  .nav-item:first-child {
    margin-left: 0;
  }
}

@include media-breakpoint-down(md) {
  .navbar .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.navbar {
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid $gray-200;
  margin-top: 4px;
}

.header-bar {
  border-top: 4px solid;
  border-image-source: linear-gradient(90deg, $primary, #8ed6fb 50%, #d32e9d);
  border-image-slice: 1;
}

.home .navbar {
  border-bottom: 0;
}

.navbar-form {
  position: relative;
  margin-top: 0.25rem;
}

@include media-breakpoint-up(md) {
  .navbar-brand {
    margin-right: 1rem !important;
  }

  .main-nav .nav-item:first-child .nav-link,
  .social-nav .nav-item:first-child .nav-link {
    padding-left: 0;
  }

  .main-nav .nav-item:last-child .nav-link,
  .social-nav .nav-item:last-child .nav-link {
    padding-right: 0;
  }

  .navbar-form {
    margin-top: 0;
    margin-left: 6rem;
    margin-right: 1.5rem;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-form {
    margin-left: 15rem;
  }
}

@include media-breakpoint-up(xl) {
  .navbar-form {
    margin-left: 30rem;
  }
}

.form-control.is-search {
  /*
  padding-right: calc(1.5em + 0.75rem);
  */
  padding-right: 2.5rem;
  background: $gray-100;
  border: 0;

  /*
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%236c757d' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  */
}

.navbar-form::after {
  position: absolute;
  top: 0.4625rem;
  right: 0.5375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  font-size: $font-size-base * 0.75;
  color: $gray-700;
  content: "/";
  border: 1px solid $gray-300;
  border-radius: 0.25rem;
}

/*! purgecss start ignore */
.algolia-autocomplete {
  display: flex !important;
}

.algolia-autocomplete .ds-dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

@include media-breakpoint-down(sm) {
  .algolia-autocomplete .ds-dropdown-menu {
    max-width: 512px !important;
    min-width: 312px !important;
    width: auto !important;
  }

  .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--subcategory-column::after {
    content: "|";
    margin-right: 0.25rem;
  }
}

.algolia-autocomplete .algolia-docsearch-suggestion--title {
  margin-bottom: 0;
}

.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  padding: 0 0.05em;
}

.algolia-autocomplete .algolia-docsearch-footer {
  margin-top: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

/*! purgecss end ignore */

/*
 * Source: https://medium.com/creative-technology-concepts-code/responsive-mobile-dropdown-navigation-using-css-only-7218e4498a99
*/

/* Style the menu icon for the dropdown */

.navbar .menu-icon {
  cursor: pointer;

  /* display: inline-block; */

  /* float: right; */
  padding: 1.125rem 0.625rem;
  margin: 0 0 0 -0.625rem;

  /* position: relative; */
  user-select: none;
}

.navbar .menu-icon .navicon {
  background: $navbar-light-color;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.navbar .menu-icon .navicon::before,
.navbar .menu-icon .navicon::after {
  background: $navbar-light-color;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.navbar .menu-icon .navicon::before {
  top: 5px;
}

.navbar .menu-icon .navicon::after {
  top: -5px;
}

/* Add the icon and menu animations when the checkbox is clicked */

.navbar .menu-btn {
  display: none;
}

.navbar .menu-btn:checked ~ .navbar-collapse {
  display: block;
  max-height: 100vh;
}

.navbar .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.navbar .menu-btn:checked ~ .menu-icon .navicon::before {
  transform: rotate(-45deg);
}

.navbar .menu-btn:checked ~ .menu-icon .navicon::after {
  transform: rotate(45deg);
}

.navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon::before,
.navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon::after {
  top: 0;
}
