// Color system

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$yellow:   #ffe000;
$black:    #1d2d35;
$beige:    #fbf7f0;
// $red:      #e55235;
$purple:   #5d2f86;
$brown:    #aa9c84;

$blue-300: #8ed6fb;
$pink-500: #d32e9d;

$primary: $purple;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true;
$enable-rounded:              true;
$enable-shadows:              false;
$enable-gradients:            false;
$enable-transitions:          true;
$enable-reduced-motion:       true;
$enable-smooth-scroll:        true;
$enable-grid-classes:         true;
$enable-button-pointers:      true;
$enable-rfs:                  true;
$enable-validation-icons:     true;
$enable-negative-margins:     true;
$enable-deprecation-messages: true;
$enable-important-utilities:  true;

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// $enable-responsive-font-sizes: true;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $black;

// Links
//
// Style anchor elements.

$link-color:                              $primary;
$link-decoration:                         none;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1240px,
  xxl: 1320px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                16;
$grid-gutter-width:           48px;
$grid-row-columns:            6;

// Components
//
// Define common padding and border radius sizes and more.

$border-color:                $gray-200;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Jost", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-xl:                $font-size-base * 1.375;
$font-size-lg:                $font-size-base * 1.25;
$font-size-md:                $font-size-base * 1.125;
$font-size-sm:                $font-size-base * 0.875;

$line-height-base:            1.5;

$headings-font-family:        null;
$headings-font-weight:        700;

$lead-font-weight:            400;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;

// Navbar

$navbar-padding-y:                  $spacer / 2;
$navbar-padding-x:                  null;

$navbar-nav-link-padding-x:         0.5rem;

$navbar-light-color:                $black;
$navbar-light-hover-color:          $primary;
$navbar-light-active-color:         $primary;

// Cards

$card-border-color:                 $gray-200;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   $spacer;
$alert-padding-x:                   $spacer * 1.5;
$alert-margin-bottom:               0;
$alert-border-radius:               0;
$alert-link-font-weight:            $headings-font-weight;
$alert-border-width:                0;

$alert-bg-scale:                    0;
$alert-border-scale:                0;
$alert-color-scale:                 0;
