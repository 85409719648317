.docs-content > h2[id]::before,
.docs-content > h3[id]::before,
.docs-content > h4[id]::before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  content: "";
}

.anchor {
  visibility: hidden;
  padding-left: 0.5rem;
}

h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a {
  visibility: visible;
  text-decoration: none;
}

.card-list {
  margin-top: 2.25rem;
}

.edit-page {
  margin-top: 3rem;
  font-size: $font-size-base;
}

.edit-page svg {
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

p.meta {
  margin-top: 0.5rem;
  font-size: $font-size-base;
}

.breadcrumb {
  margin-top: 2.25rem;
  font-size: $font-size-base;
}
