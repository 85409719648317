.footer {
  border-top: 1px solid $gray-200;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.footer ul {
  margin-bottom: 0;
}

.footer li {
  font-size: $font-size-sm;
  margin-bottom: 0;
}

@include media-breakpoint-up(md) {
  .footer li {
    font-size: $font-size-base;
  }
}
