.home .card,
.contributors.list .card,
.blog.list .card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  transition: transform 0.3s;
}

.home .card:hover,
.contributors.list .card:hover,
.blog.list .card:hover {
  transform: scale(1.025);
}

.home .card-body,
.contributors.list .card-body,
.blog.list .card-body {
  padding: 0 2rem 1rem;
}

.blog-header {
  text-align: center;
  margin-bottom: 2rem;
}

.blog-footer {
  text-align: center;
}
