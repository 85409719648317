/** Import Bootstrap functions */
@import "bootstrap/scss/functions";

/** Import theme variables */
@import "common/variables";

/** Import Bootstrap */
@import "bootstrap/scss/bootstrap";

/** Import highlight.js */
// @import "highlight.js/scss/dracula";

/** Import KaTeX */
@import "katex/dist/katex";

/** Import theme styles */
@import "common/fonts";
@import "common/global";
@import "common/dark";
@import "components/doks";
// @import "components/syntax";
@import "components/code";
@import "components/alerts";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/images";
@import "components/search";
@import "components/tables";
@import "layouts/footer";
@import "layouts/header";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/sidebar";
